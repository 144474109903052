/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

/* 
This must match the same function in gatsby-ssr.js

https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
Note: The APIs wrapPageElement and wrapRootElement exist in both the 
browser and Server-Side Rendering (SSR) APIs. You generally should implement
the same components in both gatsby-ssr.js and gatsby-browser.js so that pages
generated through SSR are the same after being hydrated in the browser.

Todo: Refactor to avoid duplicate code.
*/
const React = require('react')
const { Layout } = require('@sok-brand-sites/common-frontend-components')
const { createProfitroomScript } = require('./src/templates/utils')

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

// Workaround for Profitroom booking engine script not working
// with single page app page transitions.
// The script loads the booking engine only when the page is full-refreshed.
// Therefore, with SPA navigation, we have to remove the scripts and
// add them again to trigger the functions that load the booking engine.
exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  // The Profitroom script is added initially via gatsby-ssr.js
  // When prevLocation is null, the user has either refreshed the page or
  // the current page is the page of initial arrival. Therefore, we
  // don't have to reload Profitroom scripts to re-trigger the code.
  if (prevLocation !== null && prevLocation.pathname !== location.pathname) {
    const locale = location.pathname.startsWith('/en/') ? 'en' : 'fi'
    ;[...document.getElementsByTagName('script')].forEach(script => {
      if (script.src.includes('profitroom') || script.src.includes('upperbooking')) {
        script.remove()
      }
    })
    const newScript = createProfitroomScript({ ssr: false, locale })
    document.body.appendChild(newScript)
  }
}
