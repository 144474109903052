export const getProcessedData = ({ allWpMenu, page, wp }: any) => {
  const languages = wp.languages.map((obj: any) => obj.code)
  const currentLanguage = page?.language?.code || 'FI'
  const defaultLanguage = wp.defaultLanguage.code

  const info: { [key: string]: any[] } = {}
  allWpMenu.nodes.forEach((node: any) => {
    info[node.locations] = node.menuItems.nodes.filter((node: { parentId: string | null }) => node.parentId === null)
  })

  const headerLinks =
    languages.includes(currentLanguage) && currentLanguage !== defaultLanguage
      ? info[`GATSBY_HEADER_MENU___${currentLanguage}`]
      : info['GATSBY_HEADER_MENU']

  const footerLinks =
    languages.includes(currentLanguage) && currentLanguage !== defaultLanguage
      ? info[`GATSBY_FOOTER_MENU___${currentLanguage}`]
      : info['GATSBY_FOOTER_MENU']

  const paths = page.translations.reduce(
    (obj: any, translation: any) => ((obj[translation.language.code] = translation.uri), obj),
    { [currentLanguage]: page.uri },
  )

  const translations = {
    currentLanguage,
    order: [...languages],
    paths: { ...paths },
  }

  /*
  Example translation object:
  {
    "currentLanguage": 'FI'
    "order": [
        "FI",
        "SV",
        "EN"
    ],
    "paths": {
      "FI": "/",
      "SV": "/sv/",
      "EN": "/en/",
      "RU": "/ru/"
    }
  }
*/

  return { page, headerLinks, footerLinks, translations }
}

export const createProfitroomScript = ({ ssr = false, locale = 'fi' }) => {
  const integrity =
    locale === 'en'
      ? 'sha384-+vDfFFPSpfuUjDeyO/tMM3JzYzXXONYCL60sV+KMU/dapLCRnoU8ZiFFgpXkzDoN'
      : 'sha384-zEf2+zjSj0frkY1OoaeFL4hjmqWuCDCsabZX3ok4q6Haln7pY6x011cPO97+52lk'

  const src = `https://wis.upperbooking.com/tykkimakiresort/be-panel-${locale}.js`
  const crossOrigin = 'anonymous'
  const id = 'profitroom'

  // When the script is added via gatsby-ssr.js, we need to use JSX Elements
  // as window is not undefined as this moment still.
  // The reason why the script is added this way is because we want to add the script
  // as early as possible to minimize the delay as the booking element is loaded
  // by the script
  if (ssr) {
    const profitroomScript = <script key={id} id={id} integrity={integrity} crossOrigin={crossOrigin} src={src} async />
    return profitroomScript
  }
  // When the script needs to be reloaded, we need to use HTML DOM elements
  // because we need to re-add the script to <body> via document.body.appendChild()
  // in gatsby-browser.js to re-trigger the script's loading functions.
  else {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.id = id
    script.integrity = integrity
    script.crossOrigin = crossOrigin
    return script
  }
}
